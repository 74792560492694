import { H1, H2, P } from '@expo/html-elements';
import React, { useState } from 'react';
import { ScrollView, StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import { CuratorHeader } from '../components/curator/CuratorHeader';
import { lgBreakpoint, smBreakpoint } from '../constants';
import { useDimensions } from '../context/DimensionsContext';
import { useThemeState } from '../context/ThemeContext';
import {
  GTAmerica,
  GTAmericaCompressed,
  mdbreakpoint,
  PanamaBold,
} from '../libs/constants';

interface Props { }

export const LargeCanvasScreen: React.FC<Props> = () => {
  document.title = 'Large Canvas Painting | Happy Medium';
  const { theme } = useThemeState();
  const { width, height } = useDimensions();
  const styles = StyleSheet.create({
    text: {
      color: theme.foregroundColor,
    },
    secondaryHeader: {
      fontFamily: GTAmericaCompressed,
      textTransform: 'uppercase',
      color: theme.foregroundColor,
      fontSize: width < smBreakpoint ? 20 : width < mdbreakpoint ? 24 : 28,
    },
    body: {
      fontFamily: GTAmerica,
      fontWeight: '300',
      fontSize: width < smBreakpoint ? 16 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.07,
    },
    infoText: {
      fontFamily: GTAmericaCompressed,
      fontSize: width < smBreakpoint ? 14 : width < mdbreakpoint ? 16 : 18,
      letterSpacing: 1.02,
    },
    header: {
      fontFamily: PanamaBold,
      color: theme.foregroundColor,
      textTransform: 'uppercase',
      alignSelf: 'center',
      fontSize: width < smBreakpoint ? 34 : width < mdbreakpoint ? 42 : 48,
      letterSpacing: 1.02,
      lineHeight: width < smBreakpoint ? 38 : width < mdbreakpoint ? 48 : 54,
      textAlign: 'center',
    },
    h2: {
      fontSize: width < smBreakpoint ? 28 : width < mdbreakpoint ? 34 : 40,
    },
    h3: {
      fontSize: width < smBreakpoint ? 22 : width < mdbreakpoint ? 24 : 28,
    },
    pushButton: {
      borderWidth: 1,
      borderColor: theme.foregroundColor,
      borderRadius: 5,
      // padding: 5,
      justifyContent: 'center',
      height: 100,
      // width: 222,
      marginHorizontal: width < mdbreakpoint ? 0 : 5,
      marginVertical: 5,
    },
    timeSlot: { width: width < lgBreakpoint ? 140 : 222 },
    item: { width: 222 },
  });

  type Size = 'Large' | 'Larger' | 'Largest';
  type Date = '12/10' | '12/11' | '12/12' | '1/21' | '1/22' | '1/23';
  type Frame = 'clear pine' | 'mahogany';
  type Location = 'Manhattan' | 'Brooklyn'
  const [selectedSize, setSelectedSize] = useState<Size>('Large');
  const [selectedDate, setSelectedDate] = useState<Date>('1/21');
  const [selectedFrame, setSelectedFrame] = useState<Frame>('clear pine');
  const [selectedLocation, setSelectedLocation] = useState<Location>('Manhattan');
  type PaymentLink = {
    size: Size;
    paymentLink: string;
    price: string;
    isAvailable?: boolean;
    date: Date
    frame: Frame
    location: Location
  };
  const paymentLinks: PaymentLink[] = [

    // {
    //   size: 'Large',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/14kdUw5CQ8iien659h',
    //   price: '$350',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Large',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/5kA3fSd5ibuu92M59i',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/bIY5o0aXa7ee5QAgS1',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/bIYcQs1mAfKKen6atE',
    //   price: '$500',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/5kA8Ac9T6eGGceY9pB',
    //   price: '$450',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/13',
    //   paymentLink: 'https://buy.stripe.com/cN2aIk2qEaqqgve8ly',
    //   price: '$600',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Large',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/cN2cQs0iw6aa1Ak6dr',
    //   price: '$350',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Large',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/6oE9Eg4yM7eedj245k',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/eVa7w8d5i422a6Q59p',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/eVacQs6GUgOO3Is59q',
    //   price: '$500',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/cN217K5CQ7ee5QAatL',
    //   price: '$450',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/14',
    //   paymentLink: 'https://buy.stripe.com/fZe6s49T6eGGbaU31k',
    //   price: '$600',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Large',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/5kA3fS2qE7eebaU8lF',
    //   price: '$350',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Large',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/dR6eYAc1e8ii5QAcBW',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/dR69Eg2qEbuu7YIeK5',
    //   price: '$400',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Larger',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/8wMeYA0iw1TU92M59w',
    //   price: '$500',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/dR6bMo7KYdCC92M59x',
    //   price: '$450',
    //   isAvailable: true,
    //   frame: 'clear pine',
    //   location: 'Brooklyn'
    // },
    // {
    //   size: 'Largest',
    //   date: '8/15',
    //   paymentLink: 'https://buy.stripe.com/dR6bMo4yM1TUbaU8lK',
    //   price: '$600',
    //   isAvailable: true,
    //   frame: 'mahogany',
    //   location: 'Brooklyn'
    // },




    {
      size: 'Large',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/28o6s43uIbuufrb28H',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/7sI4jW5CQgOOen78x6',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/eVa4jW7KYcyy2Ep5kV',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/cN2eYAc1e566a6R3cO',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/dR6bMoaXa1TUen78x9',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/10',
      paymentLink: 'https://buy.stripe.com/3csbMo2qE2XY92N14I',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/dR64jW9T6fKK0wh6p3',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/3cscQs1mAdCCen7dRw',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/eVabMo2qE1TU3It4gX',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/aEUeYA4yMgOO1Al00I',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/3csbMo1mA2XYdj3cNv',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/11',
      paymentLink: 'https://buy.stripe.com/dR65o08P2gOO3It28S',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/bIYg2Efdq566en75l5',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/dR603GaXa6aa92N14Q',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/5kA6s45CQaqqgvf14R',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/3csbMofdqgOO5QB00O',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/aEUdUwaXa5662Ep3d1',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '12/12',
      paymentLink: 'https://buy.stripe.com/fZeaIk5CQaqqfrb6pe',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },

    {
      size: 'Large',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/4gw4jWc1eeGGdj36u6',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/dR6eYAfdq7eeceZ5q3',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/eVa03G8P22XY0whcSw',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/eVa17KaXagOOfrb7yd',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/8wM6s40iw1TU92NaKq',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/21',
      paymentLink: 'https://buy.stripe.com/28o7w85CQgOO4Mx6ub',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/5kAaIk3uIbuu1Alf0I',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/14kg2E6GU0PQ7YJ19T',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/4gwaIkaXaaqqen76ue',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/4gw5o01mAfKKdj3aKv',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/8wM9Eg8P20PQ3ItbOA',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/22',
      paymentLink: 'https://buy.stripe.com/cN27w86GUgOO4MxcSF',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/00g17K9T6dCCceZaKy',
      price: '$350',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Large',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/7sIeYA0iwaqq92Ng4T',
      price: '$400',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/9AQdUw8P2buubaV05W',
      price: '$400',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Larger',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/dR6eYAe9m5666UFcSJ',
      price: '$500',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/dR6eYAd5i5661Al6um',
      price: '$450',
      isAvailable: true,
      frame: 'clear pine',
      location: 'Manhattan'
    },
    {
      size: 'Largest',
      date: '1/23',
      paymentLink: 'https://buy.stripe.com/4gw2bOc1e1TU3It2e7',
      price: '$600',
      isAvailable: true,
      frame: 'mahogany',
      location: 'Manhattan'
    },

  ]

  return (
    <>
      <ScrollView style={{ backgroundColor: theme.backgroundColor }}>
        <CuratorHeader />
        <View style={{ height: width < mdbreakpoint ? 20 : 40 }} />
        <View
          style={{
            justifyContent: 'center',
            width: 700,
            maxWidth: '90vw',
            alignSelf: 'center',
          }}
        >
          <H1 style={[styles.header, { marginBottom: 0 }]}>
            Large Canvas Painting
          </H1>
          <P
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                marginTop: 0,
                letterSpacing: 1.2,
                marginBottom: 0,
                width: 280,
                textAlign: 'center',
              },
            ]}
          >
            December 10th, 11th, and 12th{'\n'}
            January 21st, 22nd, and 23rd{'\n'}
          </P>
          <View style={{ height: 10 }} />
          <Text
            style={[
              styles.text,
              styles.body,
              {
                alignSelf: 'center',
                textAlign: 'center',
                textTransform: 'uppercase',
                marginTop: 0,
                letterSpacing: 1,
                fontSize: 10,
              },
            ]}
          >
            Manhattan
          </Text>

          <P style={[styles.text, styles.body, { fontWeight: '700' }]}>
            Go big and go home.
          </P>
          <P style={[styles.text, styles.body]}>
            We all have that one spot in our home that needs a large piece of art - you know the one above the couch, bed, or entryway? Now's your chance to fill it with your own creativity.
          </P>
          <P style={[styles.text, styles.body]}>
            Join us from 6-9pm for an evening of large canvas painting at Happy Medium and leave with your new favorite piece.
          </P>
          <P style={[styles.text, styles.body]}>
            We will provide the paints, canvases, floating frames, inspiration and even sketch it for you if you want! You bring a buddy (or come solo) and get to work.
          </P>
          <P style={[styles.text, styles.body]}>
            Only at Happy Medium.
          </P>
          <P style={[styles.text, styles.body, { fontStyle: 'italic', fontSize: 14 }]}>
            **Please note, our tickets are non-refundable. You must notify us at reservations@happy-medium.co at least 72 hours before your time slot in order to be eligible to reschedule.** Local delivery available to the five boroughs only. Crate shipping available worldwide.
          </P>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your date</H2>



          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('12/10')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '12/10'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '12/10'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  December 10th (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('12/11')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '12/11'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '12/11'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  December 11th (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={true} style={{ alignSelf: 'center', opacity: 0.3 }} onPress={() => setSelectedDate('12/12')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '12/12'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDate === '12/12'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  December 12th (Manhattan) - SOLD OUT
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('1/21')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '1/21'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '1/21'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  January 21st (Manhattan)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('1/22')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '1/22'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedDate === '1/22'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  January 22nd (Manhattan)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity disabled={false} style={{ alignSelf: 'center', opacity: 1 }} onPress={() => setSelectedDate('1/23')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedDate === '1/23'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedDate === '1/23'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  January 23rd (Manhattan)
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your size</H2>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'space-around',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Large')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Large'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedSize === 'Large'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Large{'\n'}(36"x36")
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Larger')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Larger'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedSize === 'Larger'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Larger{'\n'}(40"x58")
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedSize('Largest')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedSize === 'Largest'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      color:
                        selectedSize === 'Largest'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                      textAlign: 'center'
                    },
                  ]}
                >
                  Largest{'\n'}(48"x78")
                </P>
              </View>
            </TouchableOpacity>

          </View>

          <H2 style={[styles.header, styles.h2, { marginBottom: 0 }]}>Select your frame</H2>

          <View
            style={{
              flexDirection: width < 800 ? 'column' : 'row',
              justifyContent: 'center',
              width: '100%',
              alignContent: 'space-around',
              alignSelf: 'center',
            }}
          >

            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedFrame('clear pine')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedFrame === 'clear pine'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedFrame === 'clear pine'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  clear pine{'\n'}(light)
                </P>
              </View>
            </TouchableOpacity>
            <TouchableOpacity style={{ alignSelf: 'center' }} onPress={() => setSelectedFrame('mahogany')}>
              <View
                style={[styles.pushButton, styles.item, {
                  backgroundColor:
                    selectedFrame === 'mahogany'
                      ? theme.foregroundColor
                      : theme.backgroundColor,
                }]}
              >
                <P
                  style={[
                    styles.text,
                    styles.body,
                    {
                      textAlign: 'center',
                      color:
                        selectedFrame === 'mahogany'
                          ? theme.backgroundColor
                          : theme.foregroundColor,
                    },
                  ]}
                >
                  Mahogany{'\n'}(dark)
                </P>
              </View>
            </TouchableOpacity>

          </View>
        </View>
        <View style={{ height: 80 }} />
      </ScrollView>
      <TouchableOpacity disabled={false} style={{ opacity: 1 }} onPress={() => window.open(paymentLinks.find(l => l.size === selectedSize && l.frame === selectedFrame && l.date === selectedDate)?.paymentLink, '__blank')}>
        <View style={{ position: 'absolute', bottom: 0, right: width < mdbreakpoint ? undefined : 0, backgroundColor: theme.foregroundColor, borderRadius: 50, flexDirection: 'row', justifyContent: 'space-between', padding: 15, paddingHorizontal: 60, margin: 20, width: 320, alignSelf: 'center', borderWidth: 1, borderColor: theme.backgroundColor }}>
          {/* <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Sold Out</Text> */}

          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>Checkout</Text>
          <Text style={[styles.text, styles.h3, { color: theme.backgroundColor, textTransform: 'uppercase', fontFamily: GTAmericaCompressed, letterSpacing: 1 }]}>{paymentLinks.find(l => l.size === selectedSize && l.frame === selectedFrame)?.price}</Text>
        </View>
      </TouchableOpacity>
    </>
  );
};
