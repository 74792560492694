import React, { useRef } from "react";
import {
  View,
  Text,
  StyleSheet,
  Image,
  Platform,
  Linking,
  ScrollView,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";

import { useThemeState } from "../../context/ThemeContext";
import { GTAmerica, GTAmericaCompressed } from "../../libs/constants";
import { TouchableOpacity } from "../common/TouchableOpacity";
import { useDimensions } from "../../context/DimensionsContext";
import { useLocation } from "../../navigation";
import { is } from "date-fns/locale";
import { useEffect } from "react";

interface Props {
  linkColor?: string;
  isRow?: boolean;
}

export const HeaderLinks: React.FC<Props> = React.memo(
  ({ linkColor, isRow }) => {
    const { theme } = useThemeState();
    const { width } = useDimensions();
    const location = useLocation();

    const lgBreakpoint = 1180;
    const mdBreakpoint = 860;
    const smBreakpoint = 420;
    const styles = StyleSheet.create({
      container: {
        // flexDirection: width < mdBreakpoint ? 'column' : 'column',
        // paddingHorizontal: width < lgBreakpoint || isRow ? 40 : 0,
      },
      linkContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // marginLeft: 20,
        marginRight: width < lgBreakpoint || isRow ? 20 : 0,
      },
      arrow: {
        height: 9,
        width: 9,
        marginLeft: 5,
      },
      linkLabel: {
        fontFamily: GTAmericaCompressed,
        fontWeight: "500",
        fontSize: width < mdBreakpoint ? 16 : 16,
        lineHeight: width < mdBreakpoint ? 21 : 25,
        letterSpacing: 0.85,
        textTransform: "capitalize",
        // marginRight: width < lgBreakpoint || isRow ? 15 : 0,
      },
    });

    const goArrow = require(`@happymedium/components/assets/images/arrow-up-right-${theme.logoColor
      }@1x.png`);
    const links = [
      {
        title: "Art Cafe",
        url: "/art-cafe",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: "Gift Cards",
        url: "/shop/gift-card",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: "Large Canvas Painting",
        url: "/events/large-canvas",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: "The Pottery",
        url: "/pottery",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: "Classes & Figure Drawing",
        url: "/events",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: '"Build-a-Chair" Workshop',
        url: "/events/build-a-chair",
        type: "internal",
        desktopOnly: false,
      },
      {
        title: "contact",
        url: "/contact",
        type: "internal",
        desktopOnly: false,
      },
    ];

    return (
      <View
        style={{
          margin: 10,
          width: "95%",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <ScrollView
          // contentContainerStyle={{ marginHorizontal: 40 }}
          scrollsToTop={true}
          contentContainerStyle={{
            justifyContent: "center",
            alignItems: "center",
          }}
          style={[
            styles.container,
            {
              height: 40,
              // flexGrow: 0,
              alignContent: "center",

              alignSelf: "center",
              maxWidth: "90vw",
            },
          ]}
          horizontal={true}
          showsHorizontalScrollIndicator={true}
          alwaysBounceHorizontal={true}
        // alwaysBounceVertical={true}
        >
          {links.map((link) => (
            <View
              key={link.title}
              style={[
                // styles.linkContainer,
                {
                  display:
                    link.desktopOnly && width < smBreakpoint ? "none" : "flex",
                  margin: 10,
                },
              ]}
            >
              <Hoverable>
                {(isHovered) => (
                  <TouchableOpacity
                    style={styles.linkContainer}
                    onPress={() => {
                      if (Platform.OS === "web") {
                        if (link.type === "web") {
                          window.open(link.url, "__blank");
                        } else if (
                          link.type === "mail" ||
                          link.type === "internal"
                        ) {
                          Linking.openURL(link.url);
                        }
                      } else {
                        if (link.url) {
                          Linking.openURL(link.url);
                        }
                      }
                    }}
                  >
                    <Text
                      style={[
                        styles.linkLabel,
                        {
                          color: linkColor ? linkColor : theme.foregroundColor,
                          textDecorationLine:
                            isHovered || location.pathname === link.url
                              ? "underline"
                              : "none",
                        },
                      ]}
                    >
                      {link.title}
                    </Text>
                    {link.type !== "internal" && (
                      <Image style={styles.arrow} source={goArrow} />
                    )}
                  </TouchableOpacity>
                )}
              </Hoverable>
            </View>
          ))}
        </ScrollView>
      </View>
    );
  }
);
